.checkbox{
  input{
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }
  label{
    position: relative;
    display: flex;
    cursor: pointer;
    &:before{
      content: '';
      flex-shrink: 0;
      width: 20px;
      border: 2px solid transparent;
      margin-top: 3px;
      display: block;
      height: 20px;
      margin-right: 10px;
      transition: .3s;
      border-radius: 6px;
      background: white;
    }
    &:after{
      content: '✓';
      display: block;
      z-index: 2;
      position: absolute;
      left: 5px;
      top: 4px;
      font-size: 15px;
      font-weight: 800;
      color: #fff;
      opacity: 0;
      transform: scale(0);
      transition:  0.3s;
    }
  }
  &.isError label:before{
    border: 2px solid #D53132;
  }
  input:checked+label{
    &:before{
      background: var(--primary);
    }
    &:after{
      transform: scale(0.8);
      opacity: 1;
    }
  }
}